import React from "react";
import { useNavigate } from "react-router-dom";
import Reveal from "./Reveal";

const FlipCard = ({t}) => {
  const cardItem = t(`homepage.flipCard.branchs`, {returnObjects: true})

  const navigate = useNavigate();
  return (
    <div className="bg-[#0C0805] py-16">
      <div className="w-full flex justify-center my-20">
        <div className="text-center relative inline-block">
          <p className="cormorant">{t(`homepage.flipCard.subtitle`)}</p>
          <h1 className="max-sm:text-4xl text-5xl cormorant text-white ">
          {t(`homepage.flipCard.title`)}
          </h1>
          <div className="absolute -bottom-10 right-0 max-sm:right-[30%]">
            <span className="max-sm:text-4xl text-6xl quentin">{t(`homepage.flipCard.text`)}</span>
          </div>
        </div>
      </div>
      <div className="grid max-md:grid-cols-1 max-xl:grid-cols-2 grid-cols-4 max-md:px-10 md:px-16 lg:px-32 gap-5">
        {cardItem?.map((item) => (
          <Reveal>
            <div className="card relative">
            <div className="card-inner">
              <div className="card-back ">
                <div className="absolute top-0 left-0 p-4 w-full h-full">
                  <img src={item.backImg} alt="" onClick={() => navigate(`/sube/${item?.slug}`)} />
                </div>
              </div>
              <div className="card-front relative">
              <img
                  src={item?.img}
                  alt="Arka Yüz Resmi"
                  className="w-full h-full"
                />
                <div className="absolute z-10">
                  <h1 className="text-4xl cormorant-title text-white">
                    {item?.title}
                  </h1>
                  <div className="absolute -z-10 bg-black opacity-50 w-full h-full top-0 left-0" />
                </div>
              </div>
            </div>
          </div>
          </Reveal>
        ))}
      </div>
    </div>
  );
};

export default FlipCard;

import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from 'leaflet';
import "leaflet/dist/leaflet.css";

const Location = ({position}) => {
  const icon = new L.Icon({
    iconUrl: 'https://unpkg.com/leaflet/dist/images/marker-icon.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });
  return (
    <div className="relative z-10">
      <MapContainer center={position} zoom={20} className="w-full min-h-[500px] ">
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        
      />
      <Marker position={position} icon={icon}>
        <Popup>Dükkanımız burada!</Popup>
      </Marker>
    </MapContainer>
    </div>
  );
};

export default Location;

import React, { useEffect, useRef, useState } from "react";
import { useSpring, to, animated, config } from "@react-spring/web";
import { scale, dist } from "vec-la";
import { useDrag } from "react-use-gesture";
import Reveal from "./Reveal";

const Rocket = ({ startPos, img }) => {

  const [{ pos }, api] = useSpring(() => ({ pos: startPos }));
  const [{ angle }, angleApi] = useSpring(() => ({
    angle: 0,
    config: config.wobbly,
  }));

  const bind = useDrag(
    ({ xy, previous, down, movement: pos, velocity, direction }) => {
      api.start({
        pos,
        immediate: down,
        config: { velocity: scale(direction, velocity), decay: true },
      });

      if (dist(xy, previous) > 10 || !down) {
        angleApi.start({ angle: Math.atan2(direction[0], -direction[1]) });
      }
    },
    { initial: () => pos.get() }
  );

  return (
    <animated.div
      className={`rocket ${img}`}
      {...bind()}
      style={{
        transform: to(
          [pos, angle],
          ([x, y], a) => `translate3d(${x}px, ${y}px, 0) rotate(${a}rad)`
        ),
      }}
    />
  );
};

const CoffeeLayout = ({t}) => {
  const [height, setHeight] = useState(0);
  const wrapperRef = useRef();

  useEffect(() => {
    setHeight(wrapperRef.current.getBoundingClientRect().height);
  }, []);

  return (
    <div className="flex justify-center relative">
      <div
        className="relative z-20 w-full min-h-screen h-full bg-gradient-to-b from-[#0C0805] to-[#171718]"
        ref={wrapperRef}
      >
        <div className="relative z-10">
          <Reveal>
            <Rocket
            img={" r-img-1"}
              startPos={[
                Math.random() * (window.innerWidth - 1) + 1,
                Math.random() * (height - 1) + 1,
              ]}
            />
          </Reveal>
          <Reveal>
            <Rocket
            img={"r-img-3"}
              startPos={[
                Math.random() * (window.innerWidth - 1) + 1,
                Math.random() * (height - 1) + 1,
              ]}
            />
          </Reveal>
          <Reveal>
            <Rocket
            img={"r-img-4"}
              startPos={[
                Math.random() * (window.innerWidth - 1) + 1,
                Math.random() * (height - 1) + 1,
              ]}
            />
          </Reveal>
          <Reveal>
            <Rocket
            img={"r-img-5"}
              startPos={[
                Math.random() * (window.innerWidth - 1) + 1,
                Math.random() * (height - 1) + 1,
              ]}
            />
          </Reveal>
          <Reveal>
            <Rocket
            img={"r-img-6"}
              startPos={[
                Math.random() * (window.innerWidth - 1) + 1,
                Math.random() * (height - 1) + 1,
              ]}
            />
          </Reveal>
          <Reveal>
            <Rocket
            img={"r-img-7"}
              startPos={[
                Math.random() * (window.innerWidth - 1) + 1,
                Math.random() * (height - 1) + 1,
              ]}
            />
          </Reveal>
          <Reveal>
            <Rocket
            img={"r-img-8"}
              startPos={[
                Math.random() * (window.innerWidth - 1) + 1,
                Math.random() * (height - 1) + 1,
              ]}
            />
          </Reveal>
        </div>
        <Reveal>
          <div className="flex justify-center items-center -mt-[700px] text-white relative">
            <div className="w-3/4">
              <h1 className=" max-md:text-5xl text-8xl text-center cormorant mb-5 text-white">
                MENDEL's
              </h1>
              <p className="text-center">{t(`homepage.heros.subtitle`)}</p>
            </div>
          </div>
        </Reveal>
        <div className="absolute text-center w-full bottom-0 mb-10">
          <Reveal>
            <i class="ri-arrow-up-double-line text-3xl text-white flex justify-center arrow mb-2"></i>
            <p className="text-white">{t(`homepage.heros.text`)}</p>
          </Reveal>
        </div>
      </div>
    </div>
  );
};

export default CoffeeLayout;

import React, { useEffect, useRef, useState } from "react";
import Reveal from "./Reveal";
import Button from "./Button";
import { products } from "../../constants/products";

const BestProducts = ({t}) => {
  const contentRef = useRef();
  const imgRef = useRef();
  const [contentHeight, setContentHeight] = useState(0);
  const [imgHeight, setImgHeight] = useState(0);

  const productItems = t(`products`, {returnObjects: true}).slice(-4);

  useEffect(() => {
    if (contentRef.current.scrollHeight) {
      setContentHeight(contentRef.current.scrollHeight);
    }

    if (imgRef.current.scrollHeight) {
      setImgHeight(contentRef.current.scrollHeight);
    }
  }, [contentRef, imgRef]);

  return (
    <div className="lg:relative -mt-32 ">
      <div className="bg-[#0C0805]  max-lg:mt-32">
        <div className="w-full flex justify-center">
          <div className="text-center relative inline-block">
            <p className="cormorant">{t(`homepage.products.subtitle`)}</p>
            <h1 className="max-sm:text-4xl text-5xl cormorant text-white">{t(`homepage.products.title`)}</h1>
            <div className="absolute -bottom-10 right-0 max-sm:right-[30%]">
              <span className="max-sm:text-4xl text-6xl quentin">{t(`homepage.products.text`)}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gradient-to-b from-[#0C0805] to-black flex justify-center">
        <div className="py-16 max-sm:px-5 xl: w-[90%] top-32 ">
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-5 text-center ">
            {productItems?.map((item) => (
              <Reveal>
                <a href={`/urun/${item?.category?.slug}/${item?.slug}`}>
                <div className="group grid-card-item cursor-pointer">
                  <div className="relative" ref={imgRef}>
                    <img
                      src={item?.images[0]?.img}
                      alt=""
                      className="group-hover:scale-95 transition-all max-h-[400px] min-h-[400px] w-full"
                    />
                    <div
                      className="absolute left-0 opacity-0 group-hover:opacity-100 group-hover:left-[45%] transition-all"
                      style={{ bottom: `${imgHeight / 2 }px` }}
                    >
                      <button className="text-white bg-[#171718] rounded-lg max-sm:p-1 p-3">
                        <i class="ri-eye-line text-xl p-1"></i>
                      </button>
                    </div>
                  </div>
                  <div className="" ref={contentRef}>
                    <h1 className="text-white max-sm:text-xl text-2xl my-4 cormorant">
                      {item?.title}
                    </h1>

                    <p className="text-[#ababab] mb-4 product-desc">{item?.subtitle}</p>
                    <Button text={t(`homepage.buttonText`)} url={`/urun/${item?.category?.slug}/${item?.slug}`} />
                  </div>
                </div>
                </a>
              </Reveal>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BestProducts;

import React from "react";

const Video = ({t}) => {
  return (
    <div className="min-h-[50vh] pb-10 from-[#0C0805] to-transparent bg-gradient-to-t">
      <div className="w-full flex justify-center my-20">
        <div className="text-center relative inline-block bg-transparent">
          <p className="cormorant">{t(`homepage.video.subtitle`)}</p>
          <h1 className="max-sm:text-4xl text-5xl cormorant text-white ">{t(`homepage.video.title`)}</h1>
          <div className="absolute -bottom-10 right-0 max-sm:right-[30%]">
            <span className="max-sm:text-4xl text-6xl quentin">{t(`homepage.video.text`)}</span>
          </div>
        </div>
      </div>
      <div className="flex justify-center w-full" style={{aspectRatio: "16 / 9",}}>
        <video
          className="h-full w-full object-cover"
          autoPlay
          loop
          muted
        >
          <source src="/videos/video.mp4" type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export default Video;

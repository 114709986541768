import React from "react";
import { useTranslation } from "react-i18next";

const BlogLeftSection = () => {
  const { t, i18n } = useTranslation();
  const categories = t(`categories`, {returnObjects: true});
  const blogs = t(`blogs`, {returnObjects: true});
  const socialMediaItems = [
    {
      url: "https://www.instagram.com/mendelscomtr/",
      icon: "ri-instagram-line",
    }
  ];
  return (
    <>
    <div className="bg-[#ababab] h-[1px] w-full my-5 lg:hidden " />
      <div className="max-lg:px-10">
        <h1 className="text-3xl max-lg:text-center cormorant-title">{t(`blogLeftSection.post-text`)}</h1>
        <div className="grid max-sm:grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 gap-3 max-md:relative z-30">
          {blogs.reverse().map((blog) => (
            <a href={`/blog/${blog?.slug}`}>
              <div className="latest-blog-item flex max-lg:flex-col items-center gap-2 overflow-hidden group">
                <div className="">
                  <img src={blog.img} alt="blog-img" className="lg:max-w-20 group-hover:scale-105" style={{transition: "all ease-in-out .4s"}} />
                </div>
                <div className="text-start w-full">
                  <span className="">{blog.createdAt}</span>
                  <h1 className="">{blog.title}</h1>
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
      <div className="bg-[#ababab] h-[1px] w-full my-5" />

      <div className="max-lg:px-10 mt-5 relative z-30">
        <h1 className="text-3xl max-lg:text-center cormorant-title">{t(`blogLeftSection.cate-text`)}</h1>
        <div className="flex flex-col max-lg:items-center gap-3">
          {
            categories?.map((cate) => (
              <span><a href={`/urunler/${cate?.slug}`}>{cate?.text}</a></span>
            ))
          }
        </div>
      </div>
      <div className="bg-[#ababab] h-[1px] w-full my-5" />
      <div className="">
        <h1 className="text-3xl cormorant-title max-lg:text-center">{t(`blogLeftSection.social-text`)}</h1>
        <div className="max-lg:px-10">
          <div className="grid grid-cols-2">
            <img src="/images/follow-us-1.jpg" alt="" />
            <img src="/images/follow-us-2.jpg" alt="" />
            <img src="/images/follow-us-3.jpg" alt="" />
            <img src="/images/follow-us-4.jpg" alt="" />
          </div>
        </div>
        <div className="flex text-center flex-col mt-5 ">
          <h1 className="text-xl cormorant mb-2">{t(`blogLeftSection.social-text-2`)}</h1>
          <div className="flex gap-5 justify-center">
            {socialMediaItems?.map((item) => (
              <a href={item?.url}>
                <i class={`${item?.icon} text-3xl`}></i>
              </a>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogLeftSection;

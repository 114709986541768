import React, { useState } from "react";
import { useSprings, animated, to as interpolate } from "@react-spring/web";
import { useDrag } from "react-use-gesture";
import Reveal from "./Reveal";
import { useNavigate } from "react-router-dom";

const cards = [
  {
    img: "../images/pull-me-kirmizi-4.jpg",
    url: "/urun/pull-me/pull-me-kirmizi-meyveler",
    text: "Pull Me Kırmızı Meyveler",
  },
  {
    img: "../images/chatelet-3.jpg",
    url: "/urun/kek-ve-çikolata-dansı/chatelet",
    text: "Chatelet",
  },
  {
    img: "../images/clothilde-2.jpg",
    url: "/urun/bakery/clothilde",
    text: "Clothilde",
  },
  {
    img: "../images/pull-me-lotus-2.jpg",
    url: "/urun/pull-me/pull-me-lotus-cikolata",
    text: "Pull Me Lotus Çikolata",
  },
  {
    img: "../images/brownie-2.jpg",
    url: "/urun/kek-ve-çikolata-dansı/sizziling-brownie",
    text: "Sizziling Brownie",
  },
];

const to = (i) => ({
  x: 0,
  y: i * -4,
  scale: 1,
  rot: -10 + Math.random() * 20,
  delay: i * 100,
});
const from = () => ({ x: 0, rot: 0, scale: 1.5, y: -1000 });
const trans = (r, s) =>
  `perspective(1500px) rotateX(30deg) rotateY(${
    r / 10
  }deg) rotateZ(${r}deg) scale(${s})`;

function Deck() {
  const navigate = useNavigate();
  const [gone] = useState(() => new Set());
  const [props, api] = useSprings(cards.length, (i) => ({
    ...to(i),
    from: from(i),
  }));

  const bind = useDrag(
    ({ args: [index], down, movement: [mx], direction: [xDir], velocity }) => {
      const trigger = velocity > 0.2;
      const dir = xDir < 0 ? -1 : 1;
      if (!down && trigger) gone.add(index);

      api.start((i) => {
        if (index !== i) return;
        const isGone = gone.has(index);
        const x = isGone ? (200 + window.innerWidth) * dir : down ? mx : 0;
        const rot = mx / 100 + (isGone ? dir * 10 * velocity : 0);
        const scale = down ? 1.1 : 1;
        return {
          x,
          rot,
          scale,
          delay: undefined,
          config: { friction: 50, tension: down ? 800 : isGone ? 200 : 500 },
        };
      });

      if (!down && gone.size === cards.length) {
        setTimeout(() => {
          gone.clear();
          api.start((i) => to(i));
        }, 600);
      }
    }
  );

  return (
    <>
      {props.map(({ x, y, rot, scale }, i) => (
        <animated.div className={"deck"} key={i} style={{ x, y }}>
          <animated.div
            {...bind(i)}
            className={"flex items-end justify-center"}
            style={{
              transform: interpolate([rot, scale], trans),
              backgroundImage: `url(${cards[i].img})`,
            }}
          >
            <p className="mb-2 select-none">{cards[i].text}</p>
          </animated.div>
        </animated.div>
      ))}
    </>
  );
}

const SwipeCard = ({ t }) => {
  return (
    <div className="bg-gradient-to-b from-[#0C0805] to-transparent max-sm:py-10">
      <div className="w-full flex justify-center -mb-60">
        <Reveal>
          <div className="text-center relative inline-block">
            <p className="cormorant">{t(`homepage.swiperCard.subtitle`)}</p>
            <h1 className="max-sm:text-4xl text-5xl cormorant text-white ">
              {t(`homepage.swiperCard.title`)}
            </h1>
            <div className="absolute -bottom-10 right-0 max-sm:right-[30%]">
              <span className="max-sm:text-4xl text-6xl quentin">
                {t(`homepage.swiperCard.title-text`)}
              </span>
            </div>
          </div>
        </Reveal>
      </div>
      <Reveal>
        <div className="w-full h-[800px] flex justify-center">
          <div className={"container mt-32"}>
            <Deck />
          </div>
        </div>
      </Reveal>

      <p className="blinking-text text-white text-center">
        <i class="ri-arrow-left-line"></i> {t(`homepage.swiperCard.text`)}{" "}
        <i class="ri-arrow-right-line"></i>
      </p>
    </div>
  );
};

export default SwipeCard;

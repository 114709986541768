import React from "react";
import { useTranslation } from "react-i18next";

const AboutUs = () => {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <div className="relative z-10">
        <img
          src="/images/about-us-img.jpg"
          alt="img"
          className="w-full h-[500px]"
        />
      </div>

      <div className="bg-[#FCF8ED]">
        <div className="w-full flex justify-center pb-20 pt-10">
          <div className="text-center relative inline-block">
            <h1 className="max-sm:text-4xl text-5xl cormorant">{t(`aboutUsPage.title`)}</h1>
            <div className="absolute -bottom-10 right-0 max-sm:right-[30%]">
              <span className="max-sm:text-4xl text-6xl quentin">{t(`aboutUsPage.subtitle`)}</span>
            </div>
          </div>
        </div>

        <div className="relative min-h-50vh pb-20">
          <div className="grid max-md:grid-cols-1 grid-cols-2 max-md:px-10 md:px-16 lg:px-32 gap-10">
            <div className="flex flex-col gap-3">
              <h1 className="text-center text-4xl cormorant">{t(`aboutUsPage.details.0.title`)}</h1>
              <div className="" dangerouslySetInnerHTML={{__html: t(`aboutUsPage.details.0.text`)}} />
            </div>
            <div className="flex flex-col gap-3">
              <h1 className="text-center text-4xl cormorant">{t(`aboutUsPage.details.1.title`)}</h1>
              <div className="" dangerouslySetInnerHTML={{__html: t(`aboutUsPage.details.1.text`)}} />
            </div>
          </div>

          <div className="absolute max-md:bottom-[40%] bottom-0 max-md:left-[30%] left-[40%]">
            <img src="/images/about-us-offset-img1.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;

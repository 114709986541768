import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/pages/Home";
import "./App.css";
import Header from "./components/layouts/Header";
import Footer from "./components/layouts/Footer";
import BlogDetails from "./components/pages/BlogDetails";
import Products from "./components/pages/Products";
import GetCategoryOfProduct from "./components/pages/GetCategoryOfProduct";
import ProductDetails from "./components/pages/ProductDetails";
import BranchPage from "./components/pages/BranchPage";
import AboutUs from "./components/pages/AboutUs";
import Blogs from "./components/pages/Blogs";
import { useEffect, useState } from "react";
import Loader from "./components/layouts/Loader";
import "./constants/i18n";
import { useTranslation } from "react-i18next";
function App() {
  const [loading, setLoading] = useState(true);
  const [language, setLanguage] = useState(localStorage?.getItem("language") ? localStorage?.getItem("language") : "tr");
  console.log(language);
  const { t, i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setLanguage(lang);
    localStorage.setItem("language", lang);
  };

  useEffect(() => {
    i18n.changeLanguage(language);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <Router>
      <Header />
      <div className="overflow-hidden relative">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog" element={<Blogs />} />
          <Route path="/hakkimizda" element={<AboutUs />} />
          <Route path="/blog/:slug" element={<BlogDetails />} />
          <Route path="/urunler" element={<Products />} />
          <Route path="/urunler/:category" element={<GetCategoryOfProduct />} />
          <Route path="/urun/:category/:slug" element={<ProductDetails />} />
          <Route path="/sube/:slug" element={<BranchPage />} />
        </Routes>
        <div className={`fixed bottom-10 right-10 z-20 bg-blue-500 text-white px-4 py-3 rounded-full`}>
          <button onClick={() => changeLanguage(language === "tr" ? "en" : "tr")}>
            <i class="ri-translate-2"></i>
          </button>
        </div>
      </div>
      <Footer />
    </Router>
  );
}

export default App;

import React from "react";
import Reveal from "./Reveal";

const Button = ({text, url}) => {
  return (
    <Reveal>
      <div
        className="elementor-element elementor-element-80bf9c5 elementor-widget elementor-widget-mendeles-hero text-white border"
        data-id="80bf9c5"
        data-element_type="widget"
        data-widget_type="mendeles-hero.default"
      >
        <div class="elementor-widget-container" >
          <a 
            className="qodef-shortcode qodef-m  qodef-button qodef-layout--outlined  qodef-html--link qodef-has-icon button-border"
            href={url}
            target="_self"
          >
            <span class="qodef-m-border--top-left " ></span>
            <span class="qodef-m-border--bottom-right"></span>
            <span class="qodef-m-text text-white">
              {text}
              <svg
                className="qodef-m-svg-icon"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="35.375px"
                height="11.125px"
                viewBox="0 0 35.375 12.125"
                enableBackground="new 0 0 35.375 12.125"
                xmlSpace="preserve"
              >
                <g>
                  <line x1="0.198" y1="6.078" x2="35.088" y2="6.047" />
                  <line x1="29.823" y1="1" x2="34.745" y2="5.922" />
                  <line x1="29.823" y1="11.125" x2="34.745" y2="6.203" />
                </g>
              </svg>
            </span>
          </a>
        </div>
      </div>
    </Reveal>
  );
};

export default Button;

import React from "react";
import Reveal from "../layouts/Reveal";
import BestProducts from "../layouts/BestProducts";
import BlogSection from "../layouts/BlogSection";
import Video from "../layouts/Video";
import FlipCard from "../layouts/FlipCard";
import CoffeeLayout from "../layouts/CoffeeLayout";
import SwipeCards from "../layouts/SwipeCard";
import Button from "../layouts/Button";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="">
      <CoffeeLayout t={t} />

      <Video t={t} />
      <SwipeCards t={t} />

      <div className="flex items-center max-md:px-10 md:px-16 lg:px-32 mb-16 bg-gradient-to-t from-[#0C0805] to-transparent min-h-[100vh]">
        <div className="grid max-lg:grid-cols-1 grid-cols-3 gap-10">
          <div className="flex flex-col justify-center items-center">
            <Reveal>
              <span>{t(`homepage.productPromotion.0.subtitle`)}</span>
            </Reveal>
            <Reveal>
              <h1 className="text-white text-4xl my-4 cormorant text-center">{t(`homepage.productPromotion.0.title`)}</h1>
            </Reveal>
            <Reveal>
              <p className="text-[#ababab] mb-10">{t(`homepage.productPromotion.0.text`)}</p>
            </Reveal>
            <Reveal>
              <Button text={t(`homepage.buttonText`)} url={t(`homepage.productPromotion.0.url`)} />
            </Reveal>
          </div>
          <div className="">
            <Reveal>
              <img
                src="/images/section-img-2.jpg"
                alt="section-img"
                className="w-full h-full"
              />
            </Reveal>
          </div>
          <div className="flex flex-col justify-center items-center max-lg:mb-10">
          <Reveal>
              <span>{t(`homepage.productPromotion.1.subtitle`)}</span>
            </Reveal>
            <Reveal>
              <h1 className="text-white text-4xl my-4 cormorant text-center">{t(`homepage.productPromotion.1.title`)}</h1>
            </Reveal>
            <Reveal>
              <p className="text-[#ababab] mb-10">{t(`homepage.productPromotion.1.text`)}</p>
            </Reveal>
            <Reveal>
              <Button text={t(`homepage.buttonText`)} url={t(`homepage.productPromotion.1.url`)} />
            </Reveal>
          </div>
        </div>
      </div>

      <BestProducts t={t} />

      <div className="relative">
        <div className="">
          <img
            src="/images/section-img-3.jpg"
            alt=""
            className="w-full max-h-[80vh] object-cover z-10"
          />
        </div>

        <div className="absolute flex flex-col items-center gap-5 top-20 max-sm:left-[5%] max-md:left-[10%]  max-lg:left-[10%] lg:left-[25%] xl:left-[35%]">
          <div className="flex justify-center text-white text-center">
            <div className="relative z-30">
              <Reveal>
                <a className="text-black cormorant">{t(`homepage.imageWrapper.subtitle`)}</a>
              </Reveal>
              <Reveal>
                <h1 className="text-2xl cormorant text-white">{t(`homepage.imageWrapper.title`)}</h1>
              </Reveal>
            </div>
            <span className="absolute z-20 right-0 top-[30%] quentin text-6xl">{t(`homepage.imageWrapper.text`)}</span>
          </div>
        </div>
      </div>

      <BlogSection t={t} />

      <FlipCard t={t} />
    </div>
  );
};

export default Home;

import React from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t, i18n } = useTranslation();

  const footerItems = t(`footer.footerItems`, {returnObjects: true});
  return (
    <footer
      id="qodef-page-footer"
      className="qodef-skin--none bg-black pb-16"
      role="contentinfo"
    >
      <div id="qodef-page-footer-top-area">
        <div
          id="qodef-page-footer-top-area-inner"
          className="qodef-content-grid"
        >
          <div className="qodef-grid qodef-layout--columns qodef-responsive--custom qodef-col-num--4 qodef-col-num--1024--2 qodef-col-num--768--2 qodef-col-num--680--1 qodef-col-num--480--1">
            <div className="qodef-grid-inner clear">
              <div className="qodef-grid-item">
                <div
                  id="custom_html-3"
                  className="widget_text widget widget_custom_html"
                  data-area="qodef-footer-top-area-column-1"
                >
                  <div className="textwidget custom-html-widget">
                    <a href="/">
                      <h1 className="text-4xl text-orange-500"><img src="/images/logo.png" alt="" className="w-52 h-16 object-center" /></h1>
                    </a>
                  </div>
                </div>
                <div
                  id="swissdelight_core_separator-7"
                  className="widget widget_swissdelight_core_separator"
                  data-area="qodef-footer-top-area-column-1"
                >
                  <div className="qodef-shortcode qodef-m qodef-separator clear">
                    <div
                      className="qodef-m-line"
                      style={{ borderBottomWidth: "0px", marginBottom: "40px" }}
                    ></div>
                  </div>
                </div>
              </div>

              <div className="qodef-grid-item">
                <div
                  id="nav_menu-2"
                  className="widget widget_nav_menu"
                  data-area="qodef-footer-top-area-column-2"
                >
                  <h4 className="mb-4 text-orange-500">{t(`footer.text-1`)}</h4>
                  <div className="menu-footer-column-2-container">
                    <ul id="menu-footer-column-2" className="menu">
                      {footerItems?.pages?.map((item) => (
                        <li>
                          <a href={item?.url}><p className="hover:text-orange-500 text-white transition-all">{item?.text}</p></a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div
                  id="swissdelight_core_separator-8"
                  className="widget widget_swissdelight_core_separator"
                  data-area="qodef-footer-top-area-column-2"
                >
                  <div className="qodef-shortcode qodef-m qodef-separator clear">
                    <div
                      className="qodef-m-line"
                      style={{ borderBottomWidth: "0px" }}
                    ></div>
                  </div>
                </div>
              </div>

              <div className="qodef-grid-item">
                <div
                  id="nav_menu-3"
                  className="widget widget_nav_menu"
                  data-area="qodef-footer-top-area-column-3"
                >
                  <h4 className="mb-4 text-orange-500">{t(`footer.text-2`)}</h4>
                  <div className="menu-footer-column-3-container">
                    <ul id="menu-footer-column-3" className="menu">
                      {footerItems?.favorite?.map((item) => (
                        <li>
                          <a href={item?.url}>
                          <p className="hover:text-orange-500 text-white transition-all">{item?.text}</p></a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div
                  id="swissdelight_core_separator-9"
                  className="widget widget_swissdelight_core_separator"
                  data-area="qodef-footer-top-area-column-3"
                >
                  <div className="qodef-shortcode qodef-m qodef-separator clear">
                    <div
                      className="qodef-m-line"
                      style={{ borderBottomWidth: "0px" }}
                    ></div>
                  </div>
                </div>
              </div>

              <div className="qodef-grid-item">
                <div
                  id="nav_menu-3"
                  className="widget widget_nav_menu"
                  data-area="qodef-footer-top-area-column-3"
                >
                  <h1 className="text-lg text-orange-500">{t(`footer.text-3`)}</h1>
                  <div className="mt-4 ">
                    <div className="flex flex-col gap-3 ">
                      {footerItems?.branchs?.map((item) => (
                        <a href={item?.url}>
                          <p className="hover:text-orange-500 text-white transition-all">{item?.name}</p>
                        </a>
                      ))}
                      <p className="hover:text-orange-500 text-white">
                        Tema İstanbul Çok Yakında
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useEffect, useState } from "react";
import BlogLeftSection from "../layouts/BlogLeftSection";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const GetCategoryOfProduct = () => {
  const { t, i18n } = useTranslation();
  const [products, setProducts] = useState([]);
  const [searchData, setSearchData] = useState("");
  const productData = t(`products`, {returnObjects: true});
  const { category } = useParams();

  // Arama sonucunu filtrelemek için filter kullanıyoruz
  const filteredData = products.filter((item) =>
    item.title.toLowerCase().includes(searchData.toLowerCase())
  );

  useEffect(() => {
    if (productData) {
      const filteredProducts = productData?.filter(
        (product) => product.category.slug === category
      );
      setProducts(filteredProducts);
    }
  }, [productData, category]);

  return (
    <div className="flex max-lg:flex-col gap-10 lg:py-10 lg:px-10 xl:px-32 2xl:px-64 pt-10 bg-white max-md:relative z-30">
      <div className="lg:w-4/5 max-lg:px-10">
        <div className="mb-10 max-lg:flex max-sm:flex-col justify-between sm:items-center">
          <h1 className="text-2xl">
          {t(`getCategoryOfProducts.searchArea.title`)}: <b>{category}</b>
          </h1>
          <div className="lg:hidden flex items-center gap-2">
            <h1>{t(`getCategoryOfProducts.searchArea.text`)}:</h1>
            <input
              type="text"
              onChange={(e) => setSearchData(e.target.value)}
              value={searchData}
              name="search"
              placeholder="Bir ürün giriniz..."
            />
          </div>
        </div>
        <div className="flex justify-between">
          <h1>{t(`getCategoryOfProducts.productsDetails.text`)}</h1>
          <p>{t(`getCategoryOfProducts.productsDetails.count`)} {products?.length}</p>
        </div>
        <div className="grid max-md:grid-cols-1 grid-cols-2 gap-5 mt-5 ">
          {filteredData?.reverse().map((product) => (
            <a href={`/urun/${product?.category.slug}/${product?.slug}`}>
              <div className="overflow-hidden">
                <img src={product?.images[0].img} alt="" className="hover:scale-105" style={{transition: "ease-in-out .3s all"}} />
                <h1 className="text-2xl cormorant text-center mt-5">
                  {product?.title}
                </h1>
              </div>
            </a>
          ))}
        </div>
        {filteredData?.length === 0 && (
          <div className="">
            <h1 className="text-2xl font-bold text-center">{t(`getCategoryOfProducts.notFound`)}</h1>
          </div>
        )}
      </div>
      <div className="lg:w-1/5">
        <div className="max-lg:hidden">
          <h1>{t(`getCategoryOfProducts.searchArea.text`)}:</h1>
          <input
            type="text"
            onChange={(e) => setSearchData(e.target.value)}
            value={searchData}
            name="search"
            placeholder="Bir ürün giriniz..."
          />
        </div>
        <BlogLeftSection />
      </div>
    </div>
  );
};

export default GetCategoryOfProduct;

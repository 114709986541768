import React from "react";
import Title from "./Title";
import { blogs } from "../../constants/blogs";
import Reveal from "./Reveal";

const BlogSection = ({ t }) => {
  const blogItems = t(`blogs`, { returnObjects: true }).slice(-3);
  return (
    <div className="pt-32 bg-gradient-to-t to-transparent from-[#0C0805]">
      <div className="w-full flex justify-center">
        <div className="text-center relative inline-block">
          <p className="cormorant">{t(`homepage.blogs.subtitle`)}</p>
          <h1 className="max-sm:text-4xl text-5xl cormorant text-white ">{t(`homepage.blogs.title`)}</h1>
          <div className="absolute -bottom-10 right-0 max-sm:right-[30%]">
            <span className="max-sm:text-4xl text-6xl quentin">{t(`homepage.blogs.text`)}</span>
          </div>
        </div>
      </div>
      <div className="max-sm:px-5 xl:px-32 2xl:px-60 mt-20 ">
        <div className="grid max-lg:grid-cols-1 grid-cols-3 gap-5">
          {blogItems?.map((blog) => (
            <Reveal>
              <a href={`/blog/${blog.slug}`}>
                <div className="grid-card-item cursor-pointer relative z-30 overflow-hidden">
                  <img
                    src={blog?.img}
                    alt="blog_img"
                    className="overflow-hidden"
                    style={{ transition: "transform .3s ease-in-out" }}
                  />
                  <div className="text-center flex flex-col justify-center gap-5 overflow-hidden">
                    <span className="mt-2">{blog?.createdAt}</span>
                    <h1 className="text-2xl text-white font-bold cormorant">
                      {blog?.title}
                    </h1>
                    <p className="text-[#ababab] grid-card-desc">
                      {blog?.subtitle}
                    </p>
                    <div className="flex justify-center">
                      <a
                        className="qodef-button qodef-layout--outlined"
                        style={{ border: "none" }}
                        href="https://swissdelight.qodeinteractive.com/chocolaterie-light/"
                      >
                        <span
                          class="qodef-m-text text-white"
                          style={{ border: "none" }}
                        >
                          <svg
                            className="qodef-m-svg-icon"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            width="35.375px"
                            height="11.125px"
                            viewBox="0 0 35.375 12.125"
                            enableBackground="new 0 0 35.375 12.125"
                            xmlSpace="preserve"
                          >
                            <g>
                              <line
                                x1="0.198"
                                y1="6.078"
                                x2="35.088"
                                y2="6.047"
                              />
                              <line x1="29.823" y1="1" x2="34.745" y2="5.922" />
                              <line
                                x1="29.823"
                                y1="11.125"
                                x2="34.745"
                                y2="6.203"
                              />
                            </g>
                          </svg>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </a>
            </Reveal>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogSection;

import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Breadcrumb from "../layouts/Breadcrumb";
import { useTranslation } from "react-i18next";

const ProductDetails = () => {
  const { t, i18n } = useTranslation();
  const products = t(`products`, {returnObjects : true});
  
  const [data, setData] = useState({});
  const [path, setPath] = useState([]);
  const [selectedImg, setSelectedImg] = useState();
  const [selectedBox, setSelectedBox] = useState("desc");

  const { slug } = useParams();

  useState(() => {
    if (products) {
      const fProduct = products?.find((item) => item.slug === slug);
      setData(fProduct);
      setSelectedImg(fProduct?.images[0]?.img);
      setPath([
        {
          name: t(`breadcrumb`),
          url: "/",
        },
        {
          name: fProduct?.category?.name,
          url: `/urunler/${fProduct?.category?.slug}`,
        },
        { name: fProduct?.title, url: `/urun/${fProduct?.slug}` },
      ]);
    }
  }, [products, slug]);

  return (
    <div className="bg-white">
      <div className="max-lg:px-10 pt-10 lg:px-32 2xl:px-64 max-md:relative z-30">
        <Breadcrumb paths={path} />
      </div>

      <div className="flex justify-center max-lg:flex-col max-lg:px-10 pt-10 lg:px-32 2xl:px-64 gap-10 ">
        <div className="flex max-lg:w-full justify-center w-4/5 max-md:relative z-30">
          <div className="flex max-lg:flex-col max-lg:gap-5 lg:gap-12 xl:gap-5">
            <div className="grid max-lg:grid-cols-4 max-lg:hidden grid-cols-1 gap-3 ">
              {data?.images?.map((img) => (
                <div className="">
                  <img
                    src={img?.img}
                    alt="product_img"
                    className="max-lg:w-full lg:max-w-32 h-full max-md:max-h-10 lg:max-h-32 w-full cursor-pointer ]"
                    style={
                      selectedImg === img.img
                        ? { border: "3px solid #6B5C45" }
                        : {}
                    }
                    onClick={() => setSelectedImg(img?.img)}
                  />
                </div>
              ))}
            </div>
            <div className="">
              <img
                src={selectedImg}
                alt=""
                className="w-full h-full max-h-[600px]"
              />
            </div>
            <div className="grid max-lg:grid-cols-4 lg:hidden grid-cols-1 gap-3">
              {data?.images?.map((img) => (
                <img
                  src={img?.img}
                  alt=""
                  className="max-lg:w-full lg:max-w-32 h-full cursor-pointer"
                  onClick={() => setSelectedImg(img?.img)}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="max-lg:w-full w-2/5 2xl:w-3/5">
          <h1 className="max-sm:text-4xl text-6xl cormorant-title">
            {data?.title}
          </h1>
          <p className="my-3">{data?.subtitle}</p>
          <p className="mt-4">
            <b>{t(`productDetails.text`)} </b>
            <a href={`/urunler/${data?.category?.slug}`}>
              {data?.category?.name}
            </a>
          </p>
        </div>
      </div>

      <div className="flex flex-col justify-center py-10 w-full max-lg:px-10 pt-10 lg:px-32 2xl:px-64">
        <div className="flex justify-center w-full gap-5 mb-5">
          <div className="relative group cursor-pointer" onClick={() => setSelectedBox("desc")}>
            <h1 className={`${selectedBox !== "desc" && "cormorant-title text-md" }`}>{t(`productDetails.wrapperText1`)}</h1>
            <div className={`w-0 bg-black h-[1px] group-hover:w-full transition-all mt-1 ${selectedBox === "desc" && "w-full"}`}/>
          </div>
          <div className="relative group cursor-pointer" onClick={() => setSelectedBox("details")} >
            <h1 className={`${selectedBox !== "details" && "cormorant-title text-md"}`}>{t(`productDetails.wrapperText2`)}</h1>
            <div className={`w-0 bg-black h-[1px] group-hover:w-full transition-all mt-1 ${selectedBox === "details" && "w-full" }`} />
          </div>
        </div>
        <div className="w-full bg-[#ababab] h-[1px] mb-5" />
        {selectedBox === "desc" && (
          <div className="">
            <div dangerouslySetInnerHTML={{ __html: data?.desc }} />
          </div>
        )}
        {selectedBox === "details" && (
          <div className="">
            <div dangerouslySetInnerHTML={{ __html: data?.details }} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductDetails;

import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useParams } from "react-router-dom";
import Location from "../layouts/Location.jsx";
import { useTranslation } from "react-i18next";

const BranchPage = () => {
  const { t, i18n } = useTranslation();
  const branch = t(`branchs`, {returnObjects: true});
  const { slug } = useParams();
  const [data, setData] = useState({ images: [] });
  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useState(() => {
    if (branch) {
      const fBranch = branch?.find((item) => item.slug === slug);
      setData(fBranch);
    }
  }, [branch, slug]);

  return (
    <div className="bg-white">
      <div className="">
        <img
          src={data?.images[0]}
          alt=""
          className="max-h-[500px] w-full object-cover"
        />
      </div>

      <div className="max-md:px-10 md:px-16 lg:px-32">
        <div className="">
          <div className="w-full flex justify-center mb-20 mt-10">
            <div className="text-center relative inline-block">
              <p className="cormorant">{t(`branchPage.titleText`)}</p>
              <h1 className="max-sm:text-4xl text-5xl cormorant  ">
                {data?.name}
              </h1>
              <div className="absolute -bottom-10 right-0 max-sm:right-[30%]">
                <span className="max-sm:text-4xl text-6xl quentin">
                  {data?.slug}
                </span>
              </div>
            </div>
          </div>
          <p>{data?.desc}</p>
        </div>
        <div className="grid max-md:grid-cols-1 grid-cols-2 gap-5 py-10">
          <div className="">
            <Slider {...settings}>
              {data?.images?.map((item) => (
                <img
                  src={item}
                  alt=""
                  className="min-h-[500px] max-h-[500px] h-full object-cover"
                  height={500}
                />
              ))}
            </Slider>
          </div>
          <div className="">
            <Location position={data?.position} />
          </div>
          <div
            className="flex justify-start items-center gap-2 text-white bg-gradient-to-r from-[#988779] to-[#b3a79e] p-5"
            style={{ border: "1px solid #988779" }}
          >
            <div className="">
              <i class="ri-map-pin-line text-4xl"></i>
            </div>
            <div className="">
              <h1 className="text-2xl text-white">{t(`branchPage.addressText`)}</h1>
              <a href={`${data?.address}`}>{data?.address}</a>
            </div>
          </div>
          <div
            className="flex justify-end items-center gap-2 text-white bg-gradient-to-l from-[#988779] to-[#b3a79e] p-5"
            style={{ border: "1px solid #988779" }}
          >
            <div className="text-end">
              <h1 className="text-2xl text-white">{t(`branchPage.phoneText`)}</h1>
              <a href={`tel:${data?.phoneNumber}`}>{data?.phoneNumber}</a>
            </div>
            <div className="">
            <i class="ri-phone-line text-4xl"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BranchPage;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BlogLeftSection from "../layouts/BlogLeftSection";
import { useTranslation } from "react-i18next";

const BlogDetails = () => {
  const { t, i18n } = useTranslation();
  const [blog, setBlog] = useState({});
  const { slug } = useParams();
  const blogs = t(`blogs`, { returnObjects: true })
  
  useEffect(() => {
    if (blogs) {
      blogs.find((blog) => {
        blog.slug === slug && setBlog(blog);
      });
    }
  }, [blogs, slug]);

  return (
    <div className="flex max-lg:flex-col gap-10 lg:py-10 lg:px-10 xl:px-32 2xl:px-64 bg-white ">
      <div className=" lg:w-4/5">
        <img
          src={blog.img}
          alt="blog_img"
          className="w-full max-h-[700px] object-cover xl:rounded-xl mb-5"
        />
        <div className="flex flex-col gap-5 max-lg:px-10">
          <h1 className="max-md:text-4xl text-6xl text-center cormorant ">
            {blog.title}
          </h1>
          <p>{blog.subtitle}</p>
          <div
            className=""
            dangerouslySetInnerHTML={{ __html: blog.desc }}
          ></div>
        </div>
      </div>
      <div className="lg:w-1/5">
        <BlogLeftSection />
      </div>
    </div>
  );
};

export default BlogDetails;

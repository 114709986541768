export const products = [
  {
    title: "Mösyö Gustave",
    slug: "mosyo-gustave",
    category: {
      name: "Çikolata",
      slug: "cikolata",
    },
    images: [
      {
        img: "/images/mosyo-1.jpg",
      },
      {
        img: "/images/mosyo-2.jpg",
      },
      {
        img: "/images/mosyo-3.jpg",
      },
      {
        img: "/images/mosyo-4.jpg",
      },
    ],
    subtitle:
      "Taptaze Antep fıstıklı Mendel's pandispanyasının eşsiz lezzeti, yumuşacık dokusuyla adeta ağızda eriyor. Üzerinde narin bir şekilde yayılan eritilmiş çikolata, tatlılığın doruklarına çıkarırken, taptaze dilimlenmiş muz ve çilekler pastaya ferahlatıcı bir dokunuş katıyor. En son, doğal şanti bulutlarıyla tamamlanan bu zarif lezzet şöleni, her lokmada damağınızda unutulmaz bir tat bırakmak için bir araya geliyor. Kendinizi bu kusursuz uyuma bırakın ve her bir ayrıntısıyla özenle hazırlanan bu eşsiz tatlıyı keşfedin!",
    desc: `<i class="italic">Antep fıstığının taptaze aromasıyla zenginleştirilen Mendel's pandispanyası, eritilmiş çikolatanın ipeksi dokunuşuyla buluşuyor. Her dilimde muzun yumuşak tadı ve çileğin ferahlatıcı tazeliği hissedilirken, doğal şanti katmanları bu enfes uyumu en zarif haliyle tamamlıyor. İştah açıcı bu lezzet, özel anlarınızı unutulmaz kılmak için özenle hazırlanıyor</i>`,
    details: "",
  },
  {
    title: "Saint Germain",
    slug: "saint-germain",
    category: {
      name: "Kek ve Çikolata Dansı",
      slug: "kek-ve-çikolata-dansı",
    },
    images: [
      {
        img: "/images/saint-germain-1.jpg",
      },
      {
        img: "/images/saint-germain-2.jpg",
      },
      {
        img: "/images/saint-germain-3.jpg",
      },
      {
        img: "/images/saint-germain-4.jpg",
      },
    ],
    details: "",
    subtitle:
      "Yumuşacık ve taze çilek dilimleriyle bezenmiş, pürüzsüz beyaz çikolatanın sıcak dokunuşuyla birleşen bu pasta, tatlı severler için adeta bir lezzet senfonisi sunuyor. Her lokmada, çileğin doğal ferahlığı beyaz çikolatanın kadifemsi yapısıyla harmanlanarak ağızda eriyen bir tat bırakıyor. Beyaz çikolatanın hafif ve zarif tatlılığıyla birleşen çileklerin kendine has aroması, damaklarda unutulmaz bir iz bırakırken, hem gözlere hem de ruhlara hitap eden bir şölen sunuyor. Çilek ve beyaz çikolatanın bu zarif uyumu, pasta severlere klasik tatların çok ötesinde, rafine bir tatlı deneyimi yaşatıyor.",
    desc: `<p class="italic">Taptaze çileklerin büyüleyici aromasıyla, yoğun beyaz çikolatanın zarif dokusu bir araya geliyor! Bu eşsiz pasta, beyaz çikolatanın kremsi lezzeti ile çileklerin hafif ekşimsi tadını dengeliyor ve her ısırıkta damaklarda unutulmaz bir tat şöleni yaratıyor. Yoğun lezzetlerin uyumlu buluşmasından doğan bu tatlı, hem özel anlarınızı taçlandırmak hem de günlük tatlı keyfinize zarif bir dokunuş katmak için mükemmel bir seçim.</p>`,
  },
  {
    title: "Pull Me Lotus Çikolata",
    slug: "pull-me-lotus-cikolata",
    category: {
      name: "Pull Me",
      slug: "pull-me",
    },
    images: [
      {
        img: "/images/pull-me-lotus-cikolata-1.jpg",
      },
      {
        img: "/images/pull-me-lotus-cikolata-2.jpg",
      },
      {
        img: "/images/pull-me-lotus-cikolata-3.jpg",
      },
      {
        img: "/images/pull-me-lotus-cikolata-4.jpg",
      },
    ],
    details: "",
    subtitle:
      "Mendel's pandispanyasının yoğun lezzeti, akışkan karamelin tatlı dokunuşuyla adeta bir araya geliyor ve bu özel birleşim, damaklarda unutulmaz bir tat bırakmak için hazır hale geliyor. Enfes pastacı kremasının yumuşak dokusu, Belçika'nın meşhur sütlü çikolatasının ipeksi zenginliği ile tamamlanarak pastanın her katmanına zarif bir tat katıyor. Bu özel tarifte Lotus bisküvisinin kıtır dokusu, pandispanyanın ve sütlü çikolatanın kadifemsi yapısıyla mükemmel bir uyum içinde buluşuyor. Her lokmada çikolatanın yoğun tadı, karamelin tatlı akışkanlığı ve Lotus’un kendine özgü aromasıyla benzersiz bir lezzet seremonisi yaşatan bu özel pasta, Mendel's kalitesiyle en sade anlarınızı bile unutulmaz bir kutlamaya dönüştürüyor. Kendinizi bu zengin tatların büyüsüne bırakın ve lezzet yolculuğunun keyfini çıkarın!",
    desc: `<p class="italic">Mendel's pandispanyasının yumuşak ve zengin dokusu, akışkan karamelin tatlı cazibesiyle buluşarak eşsiz bir lezzet deneyimi sunuyor. Bu muhteşem birleşimin yanında, enfes pastacı kreması, Belçika'nın meşhur sütlü çikolatasının zarif dokusuyla bütünleşiyor ve tatlıya derinlik katıyor. Lotus bisküvisinin kıtır kıtırlığı, pandispanyanın ve karamelin yumuşaklığı ile mükemmel bir uyum sağlarken, her lokmada damakta farklı bir tat patlaması yaşatıyor. Mendel's ile hazırlanan bu özel tatlı, çikolatanın, karamelin ve Lotus'un eşsiz birlikteliğiyle hem gözlerinizi hem de damağınızı şenlendirerek, her anınızı tatlandırmaya hazır!</p>`,
  },
  {
    title: "Lotus Cup",
    slug: "lotus-cup",
    category: {
      name: "Cup",
      slug: "cuplar",
    },
    images: [
      {
        img: "/images/lotus-cup-1.jpg",
      },
      {
        img: "/images/lotus-cup-2.jpg",
      },
      {
        img: "/images/lotus-cup-3.jpg",
      },
      {
        img: "/images/lotus-cup-4.jpg",
      },
    ],
    details: "",
    subtitle:
      "Belçika'nın ünlü Lotus bisküvisi, kendine özgü karamelize tadıyla tatlı severlerin favorilerindendir. Kıtır dokusu, pastacı kreması, sütlü ve beyaz çikolata, muz ve çilek gibi uyumlu tatlarla birleşerek enfes bir lezzet sunar. Lotus bisküvisi, çikolatanın kremamsı zenginliğiyle ve taze meyve aromalarıyla dengelenirken, pastacılık tariflerinde sıkça tercih edilen bir dokunuş sağlar. Kahve yanında sade olarak ya da tatlıların yıldızı olarak tüketildiğinde, Belçika’nın gururu Lotus, damaklarda unutulmaz bir iz bırakır.",
    desc: `<p class="italic">Belçika’nın dünya çapında ünlü bisküvisi Lotus, tatlı tutkunlarının vazgeçilmezi haline gelmiştir. İnce ve kıtır dokusuyla dikkat çeken bu bisküvi, kendine özgü karamelize tadı ve lezzetli aromasıyla tek başına tüketilebildiği gibi, birçok tatlı ve pastacılık tarifinin de yıldızı olmuştur. Özellikle pastacı kreması, sütlü ve beyaz çikolata, muz ve çilek gibi uyumlu tatlarla birleştiğinde eşsiz bir tat deneyimi sunar. Lotus bisküvisinin kıtır yapısı, yumuşak pastacı kremasının kadifemsi dokusuyla mükemmel bir kontrast yaratır. Muz ve çileğin doğal meyve aromaları, bu uyumu dengeleyerek taze ve hafif bir lezzet katar. Üstelik sütlü ve beyaz çikolata, tatlıya kremamsı bir zenginlik katarken, Lotus bisküvisinin karamelize notalarını daha da belirgin hale getirir. Lotus bisküvisi ile hazırlanan tatlılar, hem görselliği hem de lezzetiyle misafirlerinizi büyüleyecek özel bir dokunuş sağlar. Belçika’nın gururu olan Lotus bisküvisi, kahve eşliğinde sade olarak tüketildiğinde bile kendine hayran bırakan, zengin aromatik özelliklere sahip bir tat sunar. Pastacılıktan cheesecake ve tiramisu gibi modern tatlılara kadar geniş bir yelpazede kullanımı ile, damaklarda unutulmaz bir iz bırakmaktadır.</p>`,
  },
  {
    title: "Odeon",
    slug: "odeon",
    category: {
      name: "Kek ve Çikolata Dansı",
      slug: "kek-ve-çikolata-dansı",
    },
    images: [
      {
        img: "/images/odeon-1.jpg",
      },
      {
        img: "/images/odeon-2.jpg",
      },
      {
        img: "/images/odeon-3.jpg",
      },
      {
        img: "/images/odeon-4.jpg",
      },
    ],
    details: "",
    subtitle:
      "Taptaze muz dilimlerinin ve Antep fıstığının zengin uyumuyla hazırlanan bu özel pasta, Belçika çikolatasının yoğun ve ipeksi dokusuyla buluşarak adeta bir lezzet şölenine dönüşüyor. Üzerine dökülen sıcak eritilmiş Belçika çikolatası, pastaya eşsiz bir tat derinliği kazandırırken, her lokmada damakta unutulmaz bir iz bırakıyor. Muzun yumuşaklığı ve fıstığın kıtırlığıyla dengelenen bu pasta, zarif detaylarıyla keyif dolu bir deneyim sunuyor ve klasiklerin ötesine geçerek bir Odeon’a dönüşüyor.",
    desc: `<p class="italic">Muzun yumuşak tadı ve Antep fıstığının zengin aromasıyla hazırlanan bu özel pasta, üzerine dökülen sıcak Belçika çikolatasıyla gerçek bir lezzet şaheserine dönüşüyor. Her dilimde çikolatanın yoğun dokusu, fıstığın kıtırlığı ve muzun ferahlatıcı tadı uyum içinde hissediliyor. Damakta bıraktığı iz ve özenle hazırlanmış detaylarıyla bu enfes tat, sıradan pastaların ötesinde bir deneyim sunarak unutulmaz bir lezzet yolculuğuna davet ediyor.</p>`,
  },
  {
    title: "Pull Me Chocolate",
    slug: "pull-me-chocolate",
    category: {
      name: "Pull Me",
      slug: "pull-me",
    },
    images: [
      {
        img: "/images/pull-me-chocolate-1.jpg",
      },
      {
        img: "/images/pull-me-chocolate-2.jpg",
      },
      {
        img: "/images/pull-me-chocolate-3.jpg",
      },
      {
        img: "/images/pull-me-chocolate-4.jpg",
      },
    ],
    details: "",
    subtitle:
      "Kıtır kıtır çikolata toplarının dans ettiği, yoğun Belçika çikolatasının akışkan lezzetiyle zenginleşen “Pull Me Chocolate”, tatlı dünyasında kendine özgü bir başyapıt sunuyor. Her katmanında çikolatanın en saf ve zarif hali, çıtır topların enfes dokusuyla birleşerek benzersiz bir tat deneyimi yaratıyor. Sıradan günlerin havasını değiştiren, damağınızda unutulmaz bir iz bırakan bu tatlı, her lokmada çikolata severlere kendini yeniden keşfetme şansı sunuyor. Keyif dolu bir anın tadını çıkarmak isteyenler için hazırlanmış bu özel lezzet, her anı şölene çevirmek için birebir!",
    desc: `<p class="italic">Muzun yumuşak tadı ve Antep fıstığının zengin aromasıyla hazırlanan bu özel pasta, üzerine dökülen sıcak Belçika çikolatasıyla gerçek bir lezzet şaheserine dönüşüyor. Her dilimde çikolatanın yoğun dokusu, fıstığın kıtırlığı ve muzun ferahlatıcı tadı uyum içinde hissediliyor. Damakta bıraktığı iz ve özenle hazırlanmış detaylarıyla bu enfes tat, sıradan pastaların ötesinde bir deneyim sunarak unutulmaz bir lezzet yolculuğuna davet ediyor.</p>`,
  },
  {
    title: "Agatha Zero",
    slug: "agatha-zero",
    category: {
      name: "Kek ve Çikolata Dansı",
      slug: "kek-ve-çikolata-dansı",
    },
    images: [
      {
        img: "/images/agatha-zero-1.jpg",
      },
      {
        img: "/images/agatha-zero-2.jpg",
      },
      {
        img: "/images/agatha-zero-3.jpg",
      },
      {
        img: "/images/agatha-zero-4.jpg",
      },
    ],
    details: "",
    subtitle:
      "Taze muz dilimlerinin Antep fıstığının aromatik lezzetiyle birleştiği, üzerine dökülen yoğun eritilmiş çikolatanın ve doğal şantinin zarif dokunuşlarıyla tamamlanan bu pasta, tatlı dünyasında adeta bir başyapıt sunuyor. Antep fıstığının kıtır dokusu muzun yumuşak tadıyla mükemmel bir uyum sağlarken, eritilmiş çikolatanın kadifemsi akışı ve doğal şantinin hafifliği, her bir lokmada benzersiz bir tat şöleni yaşatıyor. Tüm detayları özenle hazırlanmış bu lezzet, sade anları bile özel kılmak için ideal bir seçim. Kendinizi bu eşsiz kek şöleninin büyüsüne bırakın ve her dilimde unutulmaz bir tat deneyimi yaşayın!",
    desc: `<p class="italic">Taptaze muz ve aromatik Antep fıstığının bir araya gelerek yarattığı bu özel pasta, üzerine dökülen yoğun eritilmiş çikolata ve doğal şanti katmanlarıyla bir lezzet şölenine dönüşüyor. Muzun yumuşacık dokusuyla bütünleşen fıstığın kendine özgü kıtırlığı, eritilmiş çikolatanın akışkan yapısı ve şantinin hafifliğiyle dengelenerek damağınızda unutulmaz bir tat bırakıyor. Özenle hazırlanan bu lezzet, her bir diliminde sıradışı bir deneyim sunarak anları daha da anlamlı kılıyor. En sade anları bile keyfe dönüştüren bu eşsiz pastanın tadını çıkarmaya davetlisiniz!</p>`,
  },
  {
    title: "Mendel's Cup",
    slug: "mendel-s-cup",
    category: {
      name: "Kup",
      slug: "kuplar",
    },
    images: [
      {
        img: "/images/mendels-cup-1.jpg",
      },
      {
        img: "/images/mendels-cup-2.jpg",
      },
      {
        img: "/images/mendels-cup-3.jpg",
      },
      {
        img: "/images/mendels-cup-4.jpg",
      },
    ],
    details: "",
    subtitle:
      "Mendel’s Cup, taptaze muz ve çileğin ferahlatıcı tadını, enfes karamelin zengin aroması ve yumuşacık pandispanyanın hafif dokusuyla buluşturuyor. Her kaşıkta damağınızda dans eden sütlü ve beyaz çikolatanın ipeksi dokunuşu, bu eşsiz tatlıya zarafet katarken, karamelin tatlı derinliğiyle de mükemmel bir uyum yakalıyor. Katman katman özenle hazırlanan Mendel’s Cup, çikolata severleri adeta bir lezzet yolculuğuna çıkarıyor. Her bir malzemenin ustalıkla bir araya geldiği bu muhteşem armoni, tatlıya dair tüm beklentilerinizi yeniden şekillendiriyor ve özel anları unutulmaz kılıyor!",
    desc: `<p class="italic">Mendel’s Cup, taptaze muz ve çileğin doğal tazeliğini, akışkan karamelin baş döndürücü aroması ve yumuşacık pandispanyanın hafifliğiyle harmanlayarak eşsiz bir tat deneyimi sunuyor. Üzerine dökülen eritilmiş sütlü ve beyaz çikolata, tatlının her katmanına ipeksi bir dokunuş ekleyerek damağınızda unutulmaz bir iz bırakıyor. Bu zarif lezzet, çikolatanın yumuşak tatlarıyla karamelin zenginliğini bir araya getirip, her lokmada adeta bir şölen yaşatıyor. Mendel's Cup ile kendinizi bu özel uyuma bırakın ve her kaşıkta tatlı bir yolculuğa çıkın!</p>`,
  },
  {
    title: "Şamdan",
    slug: "samdan",
    category: {
      name: "Special",
      slug: "special",
    },
    images: [
      {
        img: "/images/samdan-1.jpg",
      },
      {
        img: "/images/samdan-1.jpg",
      },
      {
        img: "/images/samdan-1.jpg",
      },
      {
        img: "/images/samdan-1.jpg",
      },
    ],
    details: "",
    subtitle:
      "Beyaz çikolatanın zarif bir kabuk gibi sardığı bu şık tatlı, içinde sakladığı enfes mousse kreması, taptaze çilek dilimleri ve çıtır Antep fıstığı parçacıklarıyla adeta bir lezzet seremonisi sunuyor. Her bir lokmada, beyaz çikolatanın ipeksi dokusu ve mousse kremasının hafifliği, çileğin ferahlatıcı aroması ve fıstığın kendine özgü kıtırlığıyla buluşarak damağınızda unutulmaz bir tat bırakıyor. Özenle hazırlanan ve görselliğiyle de büyüleyen bu zarif tatlı, hem gözlerinize hem de damağınıza hitap eden muhteşem bir sunumla tüm özel anlarınızı daha da anlamlı kılmaya hazır.",
    desc: `<p class="italic">Beyaz çikolatanın zarif kabuğu altında saklanan mousse kreması, taptaze çilek dilimleri ve çıtır Antep fıstığı parçacıklarıyla hazırlanan bu şık tatlı, özel anlarınıza tat katmak için özenle tasarlandı. Her lokmada beyaz çikolatanın ipeksi dokusu, mousse kremasının hafifliği, çileğin ferahlatıcı aroması ve fıstığın kıtırlığıyla adeta bir lezzet seremonisi sunuyor. Şamdan Sürprizleriniz, özel günleriniz, yeni başlangıçlarınız ve kutlamalarınız, Mendel’s’in benzersiz lezzetleriyle unutulmaz bir şölene dönüşsün. Görselliğiyle büyüleyen bu zarif tatlı, hem gözlerinize hem de damağınıza hitap ederek her anınıza tatlı bir dokunuş katmaya hazır!</p>`,
  },
  {
    title: "Pull Me Kırmızı Meyveler",
    slug: "pull-me-kirmizi-meyveler",
    category: {
      name: "Pull Me",
      slug: "pull-me",
    },
    images: [
      {
        img: "/images/pull-me-kirmizi-1.jpg",
      },
      {
        img: "/images/pull-me-kirmizi-2.jpg",
      },
      {
        img: "/images/pull-me-kirmizi-3.jpg",
      },
      {
        img: "/images/pull-me-kirmizi-4.jpg",
      },
    ],
    details: "",
    subtitle:
      "Yoğun kakao lezzetiyle ünlü, eşsiz Devil's Food Cake, enfes pastacı kreması ve ipeksi sütlü Belçika çikolatasının uyumuyla bir araya gelerek gerçek bir tat şöleni sunuyor. Bu zengin tatlı, orman meyvelerinin taptaze aromasıyla buluşup her lokmada damakta unutulmaz bir lezzet patlaması yaratıyor. Devil's Food Cake’in yoğun çikolatalı dokusu, pastacı kremasının hafifliği ve meyvelerin doğal ferahlığıyla dengelenerek, hem gözlere hem damağa hitap eden kusursuz bir uyum ortaya çıkarıyor. Sade anlardan kutlamalara kadar her özel ana tat katan bu büyüleyici lezzet, her ısırıkta mutluluk vadediyor.",
    desc: `<p class="italic">Çikolatanın en yoğun haliyle hazırlanan Devil's Food Cake, yumuşacık pastacı kreması ve sütlü Belçika çikolatasının orman meyvelerinin tazeliğiyle birleştiği enfes bir tat uyumu sunuyor. Bu özel lezzet, çikolatanın yoğun tadıyla meyvelerin ferahlatıcı dokunuşunu bir araya getirerek damakta unutulmaz bir şölen yaratıyor. Katman katman özenle hazırlanan Devil's Food Cake, her dilimde kendine özgü zengin aroması ve zarif dokusuyla anlarınızı tatlandırmak için birebir. Kutlamalarınıza ve özel anlarınıza çikolatanın büyüsünü taşıyan bu lezzet harikası, her ısırıkta mutluluğun kapısını aralıyor</p>`,
  },
  {
    title: "Pull Me Lotus",
    slug: "pull-me-lotus",
    category: {
      name: "Pull Me",
      slug: "pull-me",
    },
    images: [
      {
        img: "/images/pull-me-lotus-1.jpg",
      },
      {
        img: "/images/pull-me-lotus-2.jpg",
      },
      {
        img: "/images/pull-me-lotus-3.jpg",
      },
      {
        img: "/images/pull-me-lotus-4.jpg",
      },
    ],
    details: "",
    subtitle:
      "Mendel's pandispanyasının karamelin baş döndürücü lezzetiyle buluştuğu bu eşsiz tatlı, damaklarda unutulmaz bir iz bırakmak için özenle hazırlanmıştır. Kat kat dizilmiş pastacı kreması, Belçika'nın ünlü Lotus bisküvisinin kıtırlığı ve Lotus'un spesiyal kremasının eşsiz aromasıyla bir araya gelerek tatlı severlere benzersiz bir deneyim sunuyor. Her lokmada karamelin yumuşak akışkanlığı, bisküvinin çıtır dokusu ve kremanın pürüzsüz yapısı mükemmel bir uyum içinde hissediliyor. Özel anlarınızı taçlandıracak bu muhteşem lezzet şöleni, tatlıya dair tüm beklentilerinizi yeniden tanımlamak için sizleri bekliyor. Mendel’s ile mutluluğun tadını çıkarmaya hazır olun!",
    desc: `<p class="italic">Mendel’s pandispanyasının zengin dokusunun karamelin tatlı akışkanlığıyla harmanlandığı, Lotus bisküvisi ve spesiyal Lotus kremasının benzersiz aromasıyla bütünleşen bu tatlı, her lokmada farklı bir lezzet katmanı sunuyor. Yumuşacık pastacı kreması, karamelin yoğun tadı ve Lotus bisküvisinin çıtır dokusu bir araya gelerek unutulmaz bir tat deneyimi yaratıyor. Her detayın özenle hazırlandığı bu özel pasta, sıradan anları dahi tatlı bir şölene dönüştürmek için birebir. Mendel’s’in bu eşsiz lezzetiyle özel anlarınızı taçlandırın ve kendinizi çikolata, karamel ve Lotus’un büyüleyici uyumuna bırakın!</p>`,
  },
  {
    title: "Clothilde",
    slug: "clothilde",
    category: {
      name: "Bakery",
      slug: "bakery",
    },
    images: [
      {
        img: "/images/clothilde-1.jpg",
      },
      {
        img: "/images/clothilde-2.jpg",
      },
      {
        img: "/images/clothilde-3.jpg",
      },
      {
        img: "/images/clothilde-4.jpg",
      },
    ],
    details: "",
    subtitle:
      "Gerçek Belçika çikolatasının yoğun ve kremsi dokusu, taze meyvelerin ferahlatıcı aroması, çıtır gofretin kıtırlığı ve Mendel’s kremasının hafifliğiyle buluştuğu Clotilde, sizlere eşsiz bir kruvasan deneyimi sunmaya hazır. Her bir lokmada, çikolatanın zengin tadı ve taze meyvelerin canlılığı, gofretin kıtır kıtırlığı ile harmanlanarak damağınızda unutulmaz bir tat bırakıyor. Mendel’s kreması ise bu lezzet cümbüşüne zarif bir dokunuş ekleyerek, her ısırıkta tatlı bir sürpriz yaşatıyor. Clotilde, sıradan kahvaltıları bile şölene dönüştürecek özel bir lezzet deneyimi arayanlar için mükemmel bir seçim. Kendinizi bu eşsiz kruvasanın büyüsüne kaptırın ve kahvaltınızı ya da tatlı anlarınızı özel kılacak bu lezzet yolculuğuna çıkın!",
    desc: `<p class="italic">Gerçek Belçika çikolatasının zengin ve akışkan dokusu, taze meyvelerin ferahlatıcı lezzeti, çıtır gofretin kıtırlığı ve Mendel’s kremasının hafifliğiyle bir araya gelen Clotilde, sizlere eşsiz bir kruvasan deneyimi sunmak için tasarlandı. Her lokmada, Belçika çikolatasının yoğun tadı, meyvelerin canlı aroması ve gofretin çıtır dokusu birbirini tamamlayarak damaklarda unutulmaz bir tat patlaması yaratıyor. Mendel’s kreması ise bu muhteşem kombinasyona zarif bir dokunuş ekleyerek, tatlı anlarınızı daha da özel kılıyor. Clotilde, sıradan kahvaltıları ve tatlı molalarını birer şölen haline dönüştürmek için ideal bir seçenek. Bu eşsiz lezzet yolculuğuna çıkarak, her ısırıkta mutluluğun tadını çıkarın!</p>`,
  },
  {
    title: "Dana Jambonlu Kruvasan",
    slug: "dana-jambonlu-kruvasan",
    category: {
      name: "Bakery",
      slug: "bakery",
    },
    images: [
      {
        img: "/images/dana-kruvasan-1.jpg",
      },
      {
        img: "/images/dana-kruvasan-2.jpg",
      },
      {
        img: "/images/dana-kruvasan-3.jpg",
      },
      {
        img: "/images/dana-kruvasan-4.jpg",
      },
    ],
    details: "",
    subtitle:
      "Özenle seçilmiş dana jambon, sulu kaşar peyniri ve keskin cheddar peynirinin eşsiz uyumuyla hazırlanan bu lezzet dolu tabak, kıvırcık salatanın tazeliğiyle tamamlanarak göz alıcı bir sunumla karşınıza çıkıyor. Her lokmada, jambonun zengin aroması ve peynirlerin kremsi dokusu, kıvırcık salatanın hafifliğiyle mükemmel bir denge oluşturuyor. Taze malzemelerin bir araya geldiği bu tabak, hem damak zevkinize hitap ederken hem de sağlıklı bir seçenek sunuyor. İster atıştırmalık, ister hafif bir öğün olarak tercih edin, bu eşsiz lezzet kombinasyonu, her anınıza keyif katmaya hazır!",
    desc: `<p class="italic">Dana jambonun ince dilimleri, lezzetli kaşar peyniri ve keskin cheddar peynirinin eşsiz birleşimiyle zenginleşen bu tabak, taze kıvırcık salata ile mükemmel bir uyum içinde sunuluyor. Jambonun doyurucu ve aromatik tadı, peynirlerin kremsi dokusuyla buluşarak her lokmada damaklarda unutulmaz bir lezzet bırakıyor. Kıvırcık salatanın hafifliği ise bu tatlı ve tuzlu kombinasyona ferahlatıcı bir dokunuş katıyor. Bu lezzet dolu sunum, atıştırmalık olarak ya da hafif bir öğün olarak tercih edebileceğiniz ideal bir seçenek. Herkesin beğenisini kazanacak bu nefis tabak, keyifli anlarınıza lezzet katmaya hazır!</p>`,
  },
  {
    title: "Chatelet",
    slug: "chatelet",
    category: {
      name: "Kek ve Çikolata Dansı",
      slug: "kek-ve-çikolata-dansı",
    },
    images: [
      {
        img: "/images/chatelet-1.jpg",
      },
      {
        img: "/images/chatelet-2.jpg",
      },
      {
        img: "/images/chatelet-3.jpg",
      },
      {
        img: "/images/chatelet-4.jpg",
      },
    ],
    details: "",
    subtitle:
      "Antep fıstığının kıtır dokusu ve zengin aroması, krema ile birleşerek yoğun bir lezzet deneyimi sunan bu muhteşem pasta, eritilmiş çikolatanın akışkanlığıyla daha da derinleşiyor. Her katmanda, Antep fıstığının doğal tatlılığı, krema ve çikolatanın zengin dokusuyla ustaca harmanlanarak damaklarda unutulmaz bir şölen yaratıyor. Bu eşsiz tatlı, özellikle çikolata tutkunları için adeta bir cennet; her lokmada çikolatanın yoğun tadı ve Antep fıstığının karakteristik kıtırlığı, tatlı anlarınıza özel bir dokunuş katıyor. Görselliğiyle de büyüleyen bu pasta, hem özel günlerinizi taçlandırmak hem de sıradan anları şölene dönüştürmek için mükemmel bir tercih. Bu lezzet yolculuğuna çıkarken, Antep fıstıklı ve kremalı pastanın sunduğu doyumsuz tatların keyfini çıkarın!",
    desc: `<p class="italic">Antep fıstıklı ve kremalı pastanın zarif birleşimi, yoğun eritilmiş çikolatanın büyüleyici dokusuyla buluşarak damaklarda eşsiz bir tat deneyimi yaratıyor. Kıtır kıtır Antep fıstıkları, yumuşak kremanın hafifliği ile harmanlanarak, her katmanda derin ve doyurucu bir lezzet sunuyor. Eritilmiş çikolatanın akışkan yapısı, bu muhteşem tatlıya zarif bir dokunuş ekleyerek, her lokmada çikolatanın ve fıstığın uyumunu öne çıkarıyor. Bu özel pasta, hem görselliğiyle hem de sunduğu yoğun tatlarla özel günlerinizi ve kutlamalarınızı şölene dönüştürmek için ideal bir seçim. Antep fıstıklı ve kremalı pastanın büyüleyici lezzet yolculuğuna çıkarak, her ısırıkta mutluluğu keşfedin!</p>`,
  },
  {
    title: "Sizziling Brownie",
    slug: "sizziling-brownie",
    category: {
      name: "Kek ve Çikolata Dansı",
      slug: "kek-ve-çikolata-dansı",
    },
    images: [
      {
        img: "/images/brownie-1.jpg",
      },
      {
        img: "/images/brownie-2.jpg",
      },
      {
        img: "/images/brownie-3.png",
      },
      {
        img: "/images/brownie-4.png",
      },
    ],
    details: "",
    subtitle:
      "Gerçek Belçika çikolatasının zengin ve yoğun lezzetiyle hazırlanan Brownie, ferahlatıcı dondurmayla birlikte sunularak tatlı severlere adeta bir lezzet şöleni sunuyor. Her lokmada, çikolatanın derin aroması ve Brownie’nin yumuşak dokusu, dondurmanın serinletici etkisiyle mükemmel bir denge oluşturuyor. Bu eşsiz kombinasyon, sıcak ve soğuk tatların uyumunu bir araya getirerek damaklarda unutulmaz bir tat bırakıyor. Fırından yeni çıkmış Brownie’nin kıvamı ve dondurmanın ipeksi yapısı, tatlı anlarınıza özel bir dokunuş katıyor. Her kesimde, çikolatanın zenginliği ve dondurmanın hafifliğiyle dolu bir deneyim yaşarken, tatlı keyfinizi bir üst seviyeye taşıyor. Bugüne kadar tattıklarınız arasında en iyisi olma iddiasını taşıyan bu özel tatlı, kendinizi şımartmak için ideal bir seçim. Brownie’nin büyüleyici lezzeti ve dondurmanın serinletici etkisiyle, her anı tatlandırmaya hazır olun!",
    desc: `<p class="italic">Gerçek Belçika çikolatasıyla hazırlanan bu Brownie, damaklarda unutulmaz bir lezzet bırakmak için ferahlatıcı dondurmayla buluşuyor. Zengin çikolata aromasının yoğunluğu, Brownie’nin yumuşak dokusuyla birleşerek her lokmada tatlı bir mutluluk sunuyor. Dondurma, sıcak Brownie’nin kıvamlı dokusuyla mükemmel bir uyum içinde eriyerek, sıcak ve soğuk tatların ahengini yaratıyor. Her dilimde çikolatanın derinliği ve dondurmanın serinletici etkisi, adeta bir lezzet cümbüşü yaşatıyor. Fırından yeni çıkmış, taze ve sıcak Brownie’nin üzerine eklenen dondurma topu, bu eşsiz tatlıyı daha da özel kılıyor. Bugüne kadar tatmadığınız en iyi Brownie deneyimi için bu muhteşem kombinasyonu mutlaka denemelisiniz. Hem gözlerinizi hem de damağınızı şenlendirecek bu tatlı, tatlı anlarınızı taçlandırmaya hazır!</p>`,
  },
  {
    title: "Camelot",
    slug: "camelot",
    category: {
      name: "Kek ve Çikolata Dansı",
      slug: "kek-ve-çikolata-dansı",
    },
    images: [
      {
        img: "/images/camelot-1.jpg",
      },
      {
        img: "/images/camelot-2.jpg",
      },
      {
        img: "/images/camelot-3.jpg",
      },
      {
        img: "/images/camelot-4.jpg",
      },
    ],
    details: "",
    subtitle:
      "Mendel's farkıyla yaz akşamlarına serin bir dokunuş: Taze muz ve çileğin canlı tadı, yoğun çikolata lezzeti ve dondurmanın ferahlatıcı serinliği ile buluştu! Her lokmada yazın enerjisini hissetmek isteyenler için, Mendel's kalitesiyle hazırlanan bu özel tatlıyı keşfedin.",
    desc: `<p class="italic">Yaz akşamlarını Mendel's farkıyla tatlandırın! Taze muz ve çileğin canlı tadını, yoğun çikolata lezzeti ve serinletici dondurma ile bir araya getirerek hazırladığımız bu özel tatlı, her lokmada yazın enerjisini hissettirecek. Hem serinletici hem de lezzet dolu bu tatlı, Mendel's kalitesiyle yaz akşamlarınıza tat katmak için sizleri bekliyor!</p>`,
  },
];

import React from "react";
import BlogLeftSection from "../layouts/BlogLeftSection";
import { useTranslation } from "react-i18next";

const Blogs = () => {
  const { t, i18n } = useTranslation();
  const blogs = t(`blogs`, { returnObjects: true });
  return (
    <div className=" gap-10 lg:py-10 lg:px-10 xl:px-32 2xl:px-64 bg-white ">
      <div className="flex max-lg:flex-col gap-10 ">
        <div className="lg:w-1/5 max-lg:hidden">
          <BlogLeftSection />
        </div>
        <div className="lg:w-4/5">
          <h1 className="text-4xl mb-3 cormorant-title text-center max-lg:mt-10">
            {t(`blogPage.title`)}
          </h1>
          <div className="flex flex-col gap-10">
            {blogs?.reverse()?.map((blog) => (
              <a href={`/blog/${blog?.slug}`}>
                <div className="flex flex-col justify-center text-center gap-3 overflow-hidden">
                  <img
                    src={blog?.img}
                    alt=""
                    className="w-full max-h-[500px] object-cover rounded-xl hover:scale-95"
                    style={{ transition: "ease-in-out .3s all" }}
                  />
                  <span className="text-center">{blog?.createdAt}</span>
                  <h1 className="text-4xl cormorant-title">{blog?.title}</h1>
                  <p>{blog?.subtitle}</p>
                </div>
              </a>
            ))}
          </div>
        </div>
        <div className="lg:w-1/5 lg:hidden">
          <BlogLeftSection />
        </div>
      </div>
    </div>
  );
};

export default Blogs;

import { useEffect, useState } from "react";
import { categories } from "../../constants/categories";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t, i18n } = useTranslation();
  const [activeNavbar, setActiveNavbar] = useState("");
  const [showNavbar, setShowNavbar] = useState(false);
  const [showSideNavbar, setShowSideNavbar] = useState(false);
  const [sidebarMobile, setSidebarMobile] = useState(false);
  const [showSidebarMobile, setShowSidebarMobile] = useState({
    number: null,
    show: false,
    hidden: true,
  });
  const [showDropdown, setShowDropdown] = useState({
    number: null,
    show: false,
  });
  const categories = t(`categories`, {returnObjects: true});
  const headerItems = t(`header`, {returnObjects: true});
  useEffect(() => {
    setActiveNavbar(window.location.pathname);
    const handlerMouseMove = () => {
      if (window.scrollY > 200) {
        setShowNavbar(true);
      } else {
        setShowNavbar(false);
      }
    };

    window.addEventListener("scroll", handlerMouseMove);

    return () => window.removeEventListener("scroll", handlerMouseMove);
  }, []);
  return (
    <div
      className=""
      onMouseLeave={() => setShowDropdown({ number: null, show: false })}
    >
      <header className={``}>
        {/* HOMEPAGE-NAVBAR-STARTED */}
        <nav
          className={`z-50 w-full flex justify-between items-center max-md:px-10 md:px-20 lg:px-20 xl:px-32 h-20 top-0 transition-all ${
            showNavbar
              ? "bg-white fixed"
              : "bg-transparent absolute  text-white"
          } ${
            activeNavbar !== "/"  && "hidden"
          } `}
        >
          <div className="">
            <a href="/">
             <img src="/images/logo.png" alt="" className="w-52 h-16 object-center" />
            </a>
          </div>
          <div className="h-32">
            <ul className="flex gap-10 items-center h-full text-orange-500">
              {headerItems?.map((item) =>
                item?.dropdown ? (
                  <li
                    className="relative group max-md:hidden"
                    onMouseMove={() =>
                      setShowDropdown({
                        number: item?.dropdownNumber,
                        show: true,
                      })
                    }
                  >
                    <a
                      href={item?.url}
                      className={`hover:font-bold hover:text-orange-500 ${
                        item.url === activeNavbar &&
                        `font-bold  ${showNavbar ? "text-black" : "text-white"}`
                      }`}
                    >
                      {item?.text}
                    </a>
                    <div
                      className={`w-0 absolute h-[1px] bg-orange-500 group-hover:w-full transition-all ${
                        item.url === activeNavbar && "w-full"
                      }`}
                    />
                    <ul
                      className={`absolute top-12 -left-[50%] bg-white text-black py-3 rounded shadow-lg z-10 list-none transition-all ${
                        showDropdown &&
                        showDropdown?.number === item?.dropdownNumber
                          ? "min-h-40 min-w-[300px] "
                          : "h-0 w-0"
                      }`}
                    >
                      {showDropdown &&
                        showDropdown?.number === item?.dropdownNumber &&
                        item?.dropdownItems?.map((dItem) => (
                          <li className="px-8 py-3 ">
                            <a
                              href={`${dItem.url}`}
                              className="hover:text-orange-600 hover:font-bold hover:ms-2 transition-all block text-black"
                              style={{ transition: "all .4s ease" }}
                            >
                              {dItem.text}
                            </a>
                          </li>
                        ))}
                    </ul>
                  </li>
                ) : (
                  <li
                    className="relative group max-md:hidden"
                    onMouseMove={() =>
                      setShowDropdown({ number: null, show: false })
                    }
                  >
                    <a
                      href={item?.url}
                      className={`hover:font-bold hover:text-orange-500 ${item.url === activeNavbar && "font-bold"}`}
                    >
                      {item?.text}
                    </a>
                    <div
                      className={`w-0 absolute h-[1px] bg-orange-500 group-hover:w-full transition-all ${
                        item.url === activeNavbar && "w-full"
                      }`}
                    />
                  </li>
                )
              )}

              <div className={`max-md:hidden`}>
                <button onClick={() => setShowSideNavbar(true)}>
                  <i class="ri-menu-line text-xl"></i>
                </button>
              </div>

              <div className={`md:hidden`}>
                <button onClick={() => setSidebarMobile(true)}>
                  <i class="ri-menu-line text-xl"></i>
                </button>
              </div>
            </ul>
          </div>
        </nav>
        {/* HOMEPAGE-NAVBAR-FINISHED */}

        {/* OTHER-PAGE-NAVBAR-STARTED */}
        <div
          className={`${activeNavbar === "/" && "hidden"} pb-16`}
        >
          <nav
            className={`z-50 w-full flex justify-between items-center max-md:px-10 md:px-20 lg:px-20 xl:px-32 h-20 top-0 transition-all bg-black text-white ${
              showNavbar ? "fixed" : "absolute"
            } ${activeNavbar === "/" && "hidden"}`}
          >
            <div className="">
            <a href="/">
            <img src="/images/logo.png" alt="" className="w-52 h-16 object-center" />
            </a>
          </div>
          <div className="h-32">
            <ul className="flex gap-10 items-center h-full text-orange-500">
              {headerItems?.map((item) =>
                item?.dropdown ? (
                  <li
                    className="relative group max-md:hidden"
                    onMouseMove={() =>
                      setShowDropdown({
                        number: item?.dropdownNumber,
                        show: true,
                      })
                    }
                  >
                    <a
                      
                      className={`hover:font-bold hover:text-orange-500 ${
                        item.url === activeNavbar &&
                        `font-bold  ${showNavbar ? "text-black" : "text-white"}`
                      }`}
                    >
                      {item?.text}
                    </a>
                    <div
                      className={`w-0 absolute h-[1px] bg-orange-500 group-hover:w-full transition-all ${
                        item.url === activeNavbar && "w-full"
                      }`}
                    />
                    <ul
                      className={`absolute top-12 -left-[50%] bg-white text-black py-3 rounded shadow-lg z-10 list-none transition-all ${
                        showDropdown &&
                        showDropdown?.number === item?.dropdownNumber
                          ? "min-h-40 min-w-[300px] "
                          : "h-0 w-0"
                      }`}
                    >
                      {showDropdown &&
                        showDropdown?.number === item?.dropdownNumber &&
                        item?.dropdownItems?.map((dItem) => (
                          <li className="px-8 py-3 ">
                            <a
                              href={`${dItem.url}`}
                              className="hover:text-orange-600 hover:font-bold hover:ms-2 transition-all block text-black"
                              style={{ transition: "all .4s ease" }}
                            >
                              {dItem.text}
                            </a>
                          </li>
                        ))}
                    </ul>
                  </li>
                ) : (
                  <li
                    className="relative group max-md:hidden"
                    onMouseMove={() =>
                      setShowDropdown({ number: null, show: false })
                    }
                  >
                    <a
                      href={item?.url}
                      className={`hover:font-bold hover:text-orange-500 ${item.url === activeNavbar && "font-bold"}`}
                    >
                      {item?.text}
                    </a>
                    <div
                      className={`w-0 absolute h-[1px] bg-orange-500 group-hover:w-full transition-all ${
                        item.url === activeNavbar && "w-full"
                      }`}
                    />
                  </li>
                )
              )}

              <div className={`max-md:hidden`}>
                <button onClick={() => setShowSideNavbar(true)}>
                  <i class="ri-menu-line text-xl"></i>
                </button>
              </div>

              <div className={`md:hidden`}>
                <button onClick={() => setSidebarMobile(true)}>
                  <i class="ri-menu-line text-xl"></i>
                </button>
              </div>
            </ul>
          </div>
          </nav>
        </div>
        {/* OTHER-PAGE-NAVBAR-FINISHED */}

        {/* FOR MOBILE SIDEBAR STARTED */}
        <div
          className={`fixed w-full h-full top-0 md:hidden ${
            !sidebarMobile ? "z-0" : "z-50"
          }`}
        >
          <div
            className={`absolute h-screen transition-all w-full bg-white  ${
              !sidebarMobile ? "-top-[1000px]" : "top-0 "
            }`}
          >
            <div
              className="p-5 flex items-center justify-between border-b mb-5"
              style={{ borderBottom: "1px solid #ababab" }}
            >
              <div className="">
                <a href="/">
                  <h1 className="text-4xl font-bold text-orange-500"><img src="/images/logo.png" alt="" className="max-h-[80px] w-52 text-center" /></h1>
                </a>
              </div>
              <p
                onClick={() => setSidebarMobile(false)}
                className={`text-end ri-close-circle-line text-4xl cursor-pointer text-orange-500`}
              />
            </div>

            <div className="">
              {headerItems?.map((item) =>
                item?.dropdown ? (
                  <ul>
                    <li
                      className="flex justify-between ps-10 pe-8 cursor-pointer mb-4 "
                      onClick={() =>
                        setShowSidebarMobile({
                          number: item?.dropdownNumber,
                          show: !showSidebarMobile.show,
                          hidden: !showSidebarMobile.hidden,
                        })
                      }
                    >
                      <a
                        className={`text-2xl cursor-pointer text-[#0000008a] hover:text-orange-500 ${
                          !showSidebarMobile.hidden &&
                          showSidebarMobile.number === item?.dropdownNumber &&
                          showSidebarMobile.show &&
                          "text-orange-500"
                        }`}
                      >
                        {item?.text}
                      </a>
                      <i
                        class={`ri-arrow-right-line text-2xl transition-all transform ${
                          !showSidebarMobile.hidden &&
                          showSidebarMobile.number === item?.dropdownNumber &&
                          showSidebarMobile.show
                            ? "rotate-90 text-orange-500"
                            : "rotate-0"
                        }`}
                      />
                    </li>

                    <ul
                      className={`transition-all ${
                        showSidebarMobile.number === item?.dropdownNumber &&
                        showSidebarMobile.show
                          ? "h-full"
                          : "h-0"
                      }`}
                    >
                      {item?.dropdownItems?.map((dItem) => (
                        <li
                          className={`ps-14 py-3 group ${
                            !showSidebarMobile.hidden &&
                            showSidebarMobile.number === item?.dropdownNumber &&
                            showSidebarMobile.show
                              ? ""
                              : "hidden"
                          }`}
                        >
                          <a
                            href={dItem?.url}
                            className="text-[#0000008a] text-lg group-hover:text-orange-500"
                          >
                            {dItem?.text}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </ul>
                ) : (
                  <ul>
                    <li className="ps-10 cursor-pointer mb-4 poppins group">
                      <a
                        href={item?.url}
                        className="text-2xl text-[#0000008a] group-hover:text-orange-500"
                      >
                        {item?.text}
                      </a>
                    </li>
                  </ul>
                )
              )}
            </div>
          </div>
        </div>
        {/* FOR MOBILE SIDEBAR FINISHED */}

        {/* SIDEBAR STARTED */}
        <div
          className={`bg-[#FCF8ED] min-h-screen fixed z-50 top-0 right-0 transition-all overflow-hidden text-white ${
            showSideNavbar ? "max-sm:w-full w-[550px]" : "w-0"
          } `}
        >
          <p
            onClick={() => setShowSideNavbar(false)}
            className={`text-end text-black m-5 ri-close-circle-line text-2xl cursor-pointer z-50`}
          ></p>
          <div
            className={`flex flex-col items-center justify-center p-5 h-[70vh] ${
              !showSideNavbar && "hidden"
            }`}
          >
            <div className="flex justify-center flex-col">
            <img src="/images/logo.png" alt="" className="max-h-[150px] text-center" />
              <div className="mt-5 text-black text-center flex-col flex gap-2 justify-center w-full">
                <a href="https://maps.google.com/maps?hl=tr&gl=tr&um=1&ie=UTF-8&fb=1&sa=X&ftid=0x14cab79f72faabdf:0xec63a45c81e7c3b5" className="text-black">
                  Sinanpaşa, Şair Nedim Cd. 18/A, 34357 Beşiktaş/İstanbul
                </a>

                <a href="tel:0 212 258 42 22">+90 212 258 42 22</a>
                <a href="tel:0 212 258 42 22">mendels@iletisim.com</a>
              </div>
            </div>
            <div className="mt-10">
              <h1 className="text-center text-2xl cormorant-title">
                {t("sidebar.text")}
              </h1>
              <div className="mt-5 text-black text-center  flex gap-2 justify-center w-full">
                <a href="https://www.instagram.com/mendelscomtr/">
                  <i class="ri-instagram-line text-3xl"></i>
                </a>
                <a href="https://www.instagram.com/mendelscomtr/">
                  <i class="ri-facebook-box-line text-3xl"></i>
                </a>
                <a href="https://www.instagram.com/mendelscomtr/">
                  <i class="ri-twitter-x-line text-3xl"></i>
                </a>
              </div>
            </div>
            <div className="flex justify-center mt-10">
              <img
                src="/images/sidebar-img.jpg"
                alt="sidebar-img"
                className="w-[75%] rounded-xl"
              />
            </div>
          </div>
        </div>
        {/* SIDEBAR FINISHED */}

      </header>
    </div>
  );
};

export default Header;
